<template>
  <div>
    <v-data-table
      :headers="realTableHeader"
      :items="dataTable.data"
      :items-per-page="dataTable.data.length"
      :loading="table.loading"
      :options.sync="options"
      hide-default-footer
      class="elevation-0"
      disable-pagination
    >
      <template #item.detail="{ item }">
        <v-btn
          icon
          small
          dark
          class="primary"
          :disabled="disableWatch(item)"
          @click="
            $router.push({
              name: 'EtestDetail',
              params: { id: item.etest_class }
            })
          "
        >
          <v-icon color="white" small>mdi-eye</v-icon>
        </v-btn>
        <v-tooltip left max-width="300">
          <template #activator="{ on }">
            <span v-on="on" @click="showDialogInfoTooltip(item)">
              <v-btn
                :disabled="disableDetail(item)"
                small
                depressed
                icon
                class="primary mx-2"
              >
                <v-icon color="white" small>mdi-lead-pencil</v-icon>
              </v-btn>
            </span>
          </template>
          <span>
            {{ tooltipTxt(item) }}
          </span>
        </v-tooltip>
      </template>
      <template #item.type="{item}">
        {{ getTypeTest(item.type) }}
      </template>
      <template #item.duration="{ item }">
        {{ item.duration }} {{ $t("app.minutes") }}
      </template>
      <template #item.duration_remaining="{ item }">
        {{ item.duration_remaining }} {{ $t("app.minutes") }}
      </template>
      <template #item.chance="{ item }">
        {{ item.chance_used }} / {{ item.chance }}
      </template>
      <template #item.schedule_test="{ item }">
        {{ getDate(item.start, true) }}
        - <br />
        {{ getDate(item.end, true) }}
      </template>
      <template #item.status="{ item }">
        <v-btn
          :class="colorStatus(item.status.value)"
          :dark="parseInt(item.status.value) === 4"
          :color="parseInt(item.status.value) === 1 ? 'primary' : ''"
          :outlined="
            parseInt(item.status.value) === 1 ||
              parseInt(item.status.value) === 2
          "
          small
          depressed
          id="status"
        >
          {{ g_language === "en" ? item.status.eng : item.status.ind }}
        </v-btn>
      </template>
    </v-data-table>
    <Pagination
      :length="dataTable.last_page"
      :total="dataTable.total"
      :current-page="table.page"
      :limit="table.limit"
      :handler="paginateChange"
    />
    <DialogInfo
      :open="openInfo"
      :data="itemEtest"
      @toggleDialog="toggleDialogInfo"
      @start="startEtest"
    />
    <v-dialog v-model="dialogInfo" max-width="400" persistent>
      <v-card>
        <v-card-text class="pt-5">
          {{ txtDialogInfo }}
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn outlined color="primary" @click="dialogInfo = false">
            {{ $t("app.back") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { mapGetters } from "vuex";
import moment from "moment/moment";

export default {
  props: {
    tab: {
      type: Number,
      default: 0
    },
    dataTable: Object,
    table: Object
  },
  created() {
    moment.locale(this.g_language);
  },
  computed: {
    ...mapGetters(["g_language"])
  },
  watch: {
    options: {
      handler(param) {
        if (param.sortBy.length != 0) {
          const order = param.sortDesc[0] ? "DESC" : "ASC";
          const sort = param.sortBy[0];
          this.sort({ sort: sort, order: order });
        }
      }
    },
    tab: {
      immediate: true,
      handler(value) {
        const first = [0, 1, 9, 2, 3, 4, 6, 5, 8];
        const second = [0, 1, 9, 2, 3, 4, 6, 5, 8];
        const third = [0, 1, 9, 2, 3, 4, 6, 5, 8];
        const fourth = [0, 1, 9, 2, 3, 4, 5, 8];
        switch (value) {
          case 0:
            this.realTableHeader = first.map(d => this.tableHeaders[d]);
            break;
          case 1:
            this.realTableHeader = second.map(d => this.tableHeaders[d]);
            break;
          case 2:
            this.realTableHeader = third.map(d => this.tableHeaders[d]);
            break;
          case 3:
            this.realTableHeader = fourth.map(d => this.tableHeaders[d]);
            break;
        }
      }
    }
  },
  components: {
    Pagination: () => import("@/components/Pagination"),
    DialogInfo: () => import("./DialogInfo")
  },
  data() {
    return {
      txtDialogInfo: "",
      dialogInfo: false,
      itemEtest: {},
      openInfo: false,
      realTableHeader: [],
      options: {},
      tableHeaders: [
        {
          text: i18n.t("app.subjects"),
          value: "lesson_name",
          width: 200
        },
        {
          text: i18n.t("e_test.table_header.detail_subjects.title"),
          value: "title",
          width: 200
        },
        { text: "Status", value: "status", width: 150 },
        {
          text: i18n.t("e_test.test_schedule"),
          value: "schedule_test",
          width: 170
        },
        {
          text: i18n.t("e_test.table_header.detail_subjects.duration"),
          value: "duration"
        },
        {
          text: i18n.t("e_test.chance"),
          value: "chance"
        },
        {
          text: i18n.t("e_test.remaining_duration"),
          value: "duration_remaining"
        },
        {
          text: i18n.t("e_test.table_header.chance.remaining_opportunity"),
          value: "chance_remaining"
        },
        {
          text: this.$i18n.t("app.action"),
          align: "center",
          sortable: false,
          value: "detail",
          width: 150
        },
        {
          text: this.$i18n.t("e_test.question_type"),
          value: "type"
        }
      ]
    };
  },
  methods: {
    tooltipTxt(item) {
      let txt;
      switch (Number(item.status.value)) {
        case 1:
          txt = this.$i18n.t("e_test.msg.not_yet_test");
          break;
        case 2:
        case 3:
          if (item.chance_used >= item.chance)
            txt = this.$i18n.t("e_test.msg.date_not_passed");
          else txt = this.$i18n.t("e_test.msg.do_e_test");
          break;
        case 4:
        case 5:
          txt = this.$i18n.t("e_test.msg.date_passed");
          break;
        default:
          txt = this.$i18n.t("e_test.msg.not_yet_test");
      }
      return txt;
    },
    showDialogInfoTooltip(item) {
      const statusEtest = Number(item.status.value);
      if (
        (statusEtest === 2 || statusEtest === 3) &&
        item.chance_used < item.chance
      ) {
        this.toggleDialogInfo(item);
      } else {
        this.txtDialogInfo = this.tooltipTxt(item);
        this.dialogInfo = true;
      }
    },
    getTypeTest(type) {
      let result = "";
      switch (type) {
        case 1:
          result = this.$i18n.t("e_test.name_test.daily_test");
          break;
        case 2:
          result = this.$i18n.t("e_test.name_test.homework");
          break;
        case 3:
          result = this.$i18n.t("e_test.name_test.midterm_exam");
          break;
        case 4:
          result = this.$i18n.t("e_test.name_test.semester_exam");
          break;
      }
      return result;
    },
    startEtest(etestClass) {
      this.toggleDialogInfo();
      this.$router.push(`/academic/etest-student-question/${etestClass}`);
    },
    toggleDialogInfo(item) {
      if (this.openInfo) this.itemEtest = {};
      else this.itemEtest = item;
      this.openInfo = !this.openInfo;
    },
    disableDetail(item) {
      let disabled = true;
      switch (parseInt(item.status.value)) {
        case 2:
        case 3:
          disabled = false;
          break;
      }
      if (item.chance_used >= item.chance) disabled = true;
      return disabled;
    },
    disableWatch(item) {
      let disabled = true;
      switch (parseInt(item.publish_student)) {
        case 1:
          disabled = false;
          break;
        default:
          disabled = true;
          break;
      }
      return disabled;
    },
    colorStatus(value) {
      let color = "";
      switch (parseInt(value)) {
        case 3:
          color = "primary";
          break;
        case 4:
          color = "gradient-primary";
          break;
        case 5:
          color = "error";
          break;
        default:
          color = "";
          break;
      }
      return color;
    },
    dateFromEpoch(start, end) {
      const startDate = moment
        .unix(parseInt(start.toString().substr(0, 10)))
        .format("DD MMM");
      const endDate = moment
        .unix(parseInt(end.toString().substr(0, 10)))
        .format("DD MMM YYYY");
      return `${startDate} - ${endDate}`;
    },
    hoursFromEpoch: epoch => moment.unix(moment(epoch).unix()).format("HH:mm"),
    paginateChange(page, limit) {
      this.$emit("paginateChange", page, limit);
    },
    sort(sort) {
      this.$emit("sort", sort);
    },
    getDate: (date, hours) =>
      hours
        ? moment.unix(moment(date).unix()).format("DD MMM YYYY, HH:mm")
        : moment.unix(moment(date).unix()).format("DD MMM YYYY")
  }
};
</script>

<style lang="scss" scoped>
#status {
  pointer-events: none;
}
</style>
